import Link from 'next/link'
import { FC, memo } from 'react'
import styled from 'styled-components'
import LogoSvg from 'assets/svg/brand/logo.svg'
import ROUTES from 'constants/routes'

const SvgLogo = memo(() => {
	const currentTheme = 'dark'

	if (window.location.pathname === ROUTES.Home.Root) {
		return <LogoSvg />
	}

	return <LogoSvg />
})

const Logo: FC = memo(() => {
	return (
		<Link href={ROUTES.Home.Root}>
			<LogoContainer>
				<SvgLogo />
			</LogoContainer>
		</Link>
	)
})

const LogoContainer = styled.span`
	display: flex;
	align-items: center;
	cursor: pointer;
	height: 18px;
	width: 122px;
	margin-right: 20px;
`

export default Logo
