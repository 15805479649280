import KWENTAIcon from 'assets/png/currencies/KWENTA.png'
import SNXIcon from 'assets/png/currencies/SNX.png'
import WBTCIcon from 'assets/png/currencies/WBTC.png'
import hardIcon from 'assets/svg/crypto/hard.png'
import epxIcon from 'assets/svg/crypto/epx.png'
import bidrIcon from 'assets/svg/crypto/bidr.png'
import joeIcon from 'assets/svg/crypto/joe.png'
import mftIcon from 'assets/svg/crypto/mft.png'
import astIcon from 'assets/svg/crypto/ast.png'
import quickIcon from 'assets/svg/crypto/quick.png'
import solIcon from 'assets/svg/crypto/sol.png'
import yfiupIcon from 'assets/svg/crypto/yfiup.png'
import glmIcon from 'assets/svg/crypto/glm.png'
import bnbbearIcon from 'assets/svg/crypto/bnbbear.png'
import meetoneIcon from 'assets/svg/crypto/meetone.png'
import roseIcon from 'assets/svg/crypto/rose.png'
import paraIcon from 'assets/svg/crypto/para.png'
import ltcIcon from 'assets/svg/crypto/ltc.png'
import viteIcon from 'assets/svg/crypto/vite.png'
import enjIcon from 'assets/svg/crypto/enj.png'
import dashIcon from 'assets/svg/crypto/dash.png'
import stgIcon from 'assets/svg/crypto/stg.png'
import efiIcon from 'assets/svg/crypto/efi.png'
import gtoIcon from 'assets/svg/crypto/gto.png'
import egldIcon from 'assets/svg/crypto/egld.png'
import voxelIcon from 'assets/svg/crypto/voxel.png'
import eosdownIcon from 'assets/svg/crypto/eosdown.png'
import gvtIcon from 'assets/svg/crypto/gvt.png'
import storjIcon from 'assets/svg/crypto/storj.png'
import chrIcon from 'assets/svg/crypto/chr.png'
import renIcon from 'assets/svg/crypto/ren.png'
import cocosIcon from 'assets/svg/crypto/cocos.png'
import compIcon from 'assets/svg/crypto/comp.png'
import evxIcon from 'assets/svg/crypto/evx.png'
import zrxIcon from 'assets/svg/crypto/zrx.png'
import unfiIcon from 'assets/svg/crypto/unfi.png'
import ltoIcon from 'assets/svg/crypto/lto.png'
import lokaIcon from 'assets/svg/crypto/loka.png'
import grsIcon from 'assets/svg/crypto/grs.png'
import oaxIcon from 'assets/svg/crypto/oax.png'
import gasIcon from 'assets/svg/crypto/gas.png'
import alpacaIcon from 'assets/svg/crypto/alpaca.png'
import reiIcon from 'assets/svg/crypto/rei.png'
import ustIcon from 'assets/svg/crypto/ust.png'
import xrpbearIcon from 'assets/svg/crypto/xrpbear.png'
import auctionIcon from 'assets/svg/crypto/auction.png'
import gyenIcon from 'assets/svg/crypto/gyen.png'
import hcIcon from 'assets/svg/crypto/hc.png'
import bondIcon from 'assets/svg/crypto/bond.png'
import polyxIcon from 'assets/svg/crypto/polyx.png'
import ethIcon from 'assets/svg/crypto/eth.png'
import polsIcon from 'assets/svg/crypto/pols.png'
import bnbupIcon from 'assets/svg/crypto/bnbup.png'
import psgIcon from 'assets/svg/crypto/psg.png'
import wbnbIcon from 'assets/svg/crypto/wbnb.png'
import xlmdownIcon from 'assets/svg/crypto/xlmdown.png'
import vrabIcon from 'assets/svg/crypto/vrab.png'
import balIcon from 'assets/svg/crypto/bal.png'
import ontIcon from 'assets/svg/crypto/ont.png'
import paxgIcon from 'assets/svg/crypto/paxg.png'
import portoIcon from 'assets/svg/crypto/porto.png'
import mthIcon from 'assets/svg/crypto/mth.png'
import chessIcon from 'assets/svg/crypto/chess.png'
import swrvIcon from 'assets/svg/crypto/swrv.png'
import rcnIcon from 'assets/svg/crypto/rcn.png'
import sgbIcon from 'assets/svg/crypto/sgb.png'
import bearIcon from 'assets/svg/crypto/bear.png'
import phbIcon from 'assets/svg/crypto/phb.png'
import dgbIcon from 'assets/svg/crypto/dgb.png'
import bnbdownIcon from 'assets/svg/crypto/bnbdown.png'
import fioIcon from 'assets/svg/crypto/fio.png'
import yggIcon from 'assets/svg/crypto/ygg.png'
import snxIcon from 'assets/svg/crypto/snx.png'
import ookiIcon from 'assets/svg/crypto/ooki.png'
import promIcon from 'assets/svg/crypto/prom.png'
import betaIcon from 'assets/svg/crypto/beta.png'
import etcIcon from 'assets/svg/crypto/etc.png'
import sushidownIcon from 'assets/svg/crypto/sushidown.png'
import lptIcon from 'assets/svg/crypto/lpt.png'
import xrpdownIcon from 'assets/svg/crypto/xrpdown.png'
import qspIcon from 'assets/svg/crypto/qsp.png'
import firoIcon from 'assets/svg/crypto/firo.png'
import bcdIcon from 'assets/svg/crypto/bcd.png'
import ampIcon from 'assets/svg/crypto/amp.png'
import tkoIcon from 'assets/svg/crypto/tko.png'
import ldoIcon from 'assets/svg/crypto/ldo.png'
import icpIcon from 'assets/svg/crypto/icp.png'
import wooIcon from 'assets/svg/crypto/woo.png'
import gxsIcon from 'assets/svg/crypto/gxs.png'
import xrpupIcon from 'assets/svg/crypto/xrpup.png'
import eosbullIcon from 'assets/svg/crypto/eosbull.png'
import multiIcon from 'assets/svg/crypto/multi.png'
import ataIcon from 'assets/svg/crypto/ata.png'
import dexeIcon from 'assets/svg/crypto/dexe.png'
import barIcon from 'assets/svg/crypto/bar.png'
import yoyoIcon from 'assets/svg/crypto/yoyo.png'
import zilIcon from 'assets/svg/crypto/zil.png'
import polyIcon from 'assets/svg/crypto/poly.png'
import batIcon from 'assets/svg/crypto/bat.png'
import gntIcon from 'assets/svg/crypto/gnt.png'
import cityIcon from 'assets/svg/crypto/city.png'
import atmIcon from 'assets/svg/crypto/atm.png'
import lbaIcon from 'assets/svg/crypto/lba.png'
import ltcupIcon from 'assets/svg/crypto/ltcup.png'
import bkrwIcon from 'assets/svg/crypto/bkrw.png'
import donIcon from 'assets/svg/crypto/don.png'
import keepIcon from 'assets/svg/crypto/keep.png'
import fisIcon from 'assets/svg/crypto/fis.png'
import inchdownIcon from 'assets/svg/crypto/1inchdown.png'
import ctrIcon from 'assets/svg/crypto/ctr.png'
import yfiIcon from 'assets/svg/crypto/yfi.png'
import yfidownIcon from 'assets/svg/crypto/yfidown.png'
import bdotIcon from 'assets/svg/crypto/bdot.png'
import gtcIcon from 'assets/svg/crypto/gtc.png'
import eopIcon from 'assets/svg/crypto/eop.png'
import usdtIcon from 'assets/svg/crypto/usdt.png'
import runeIcon from 'assets/svg/crypto/rune.png'
import knclIcon from 'assets/svg/crypto/kncl.png'
import prosIcon from 'assets/svg/crypto/pros.png'
import cdtIcon from 'assets/svg/crypto/cdt.png'
import pivxIcon from 'assets/svg/crypto/pivx.png'
import eosbearIcon from 'assets/svg/crypto/eosbear.png'
import sgtIcon from 'assets/svg/crypto/sgt.png'
import bobaIcon from 'assets/svg/crypto/boba.png'
import gnoIcon from 'assets/svg/crypto/gno.png'
import spellIcon from 'assets/svg/crypto/spell.png'
import c98Icon from 'assets/svg/crypto/c98.png'
import soloIcon from 'assets/svg/crypto/solo.png'
import lrcIcon from 'assets/svg/crypto/lrc.png'
import tfuelIcon from 'assets/svg/crypto/tfuel.png'
import dntIcon from 'assets/svg/crypto/dnt.png'
import rsrIcon from 'assets/svg/crypto/rsr.png'
import maticIcon from 'assets/svg/crypto/matic.png'
import arpaIcon from 'assets/svg/crypto/arpa.png'
import repv1Icon from 'assets/svg/crypto/repv1.png'
import btgIcon from 'assets/svg/crypto/btg.png'
import nmrIcon from 'assets/svg/crypto/nmr.png'
import agldIcon from 'assets/svg/crypto/agld.png'
import undIcon from 'assets/svg/crypto/und.png'
import forIcon from 'assets/svg/crypto/for.png'
import luncIcon from 'assets/svg/crypto/lunc.png'
import hegicIcon from 'assets/svg/crypto/hegic.png'
import dcrIcon from 'assets/svg/crypto/dcr.png'
import litIcon from 'assets/svg/crypto/lit.png'
import axsIcon from 'assets/svg/crypto/axs.png'
import hbarIcon from 'assets/svg/crypto/hbar.png'
import sxpIcon from 'assets/svg/crypto/sxp.png'
import kncIcon from 'assets/svg/crypto/knc.png'
import sandIcon from 'assets/svg/crypto/sand.png'
import linkdownIcon from 'assets/svg/crypto/linkdown.png'
import adadownIcon from 'assets/svg/crypto/adadown.png'
import axsoldIcon from 'assets/svg/crypto/axsold.png'
import audioIcon from 'assets/svg/crypto/audio.png'
import ongIcon from 'assets/svg/crypto/ong.png'
import asrIcon from 'assets/svg/crypto/asr.png'
import adxoldIcon from 'assets/svg/crypto/adxold.png'
import linaIcon from 'assets/svg/crypto/lina.png'
import hnstIcon from 'assets/svg/crypto/hnst.png'
import troyIcon from 'assets/svg/crypto/troy.png'
import hccIcon from 'assets/svg/crypto/hcc.png'
import loomIcon from 'assets/svg/crypto/loom.png'
import rvnIcon from 'assets/svg/crypto/rvn.png'
import mithIcon from 'assets/svg/crypto/mith.png'
import ilvIcon from 'assets/svg/crypto/ilv.png'
import dodoIcon from 'assets/svg/crypto/dodo.png'
import bcxIcon from 'assets/svg/crypto/bcx.png'
import ctkIcon from 'assets/svg/crypto/ctk.png'
import viaIcon from 'assets/svg/crypto/via.png'
import drepoldIcon from 'assets/svg/crypto/drepold.png'
import rampIcon from 'assets/svg/crypto/ramp.png'
import grtIcon from 'assets/svg/crypto/grt.png'
import dentIcon from 'assets/svg/crypto/dent.png'
import degoIcon from 'assets/svg/crypto/dego.png'
import epsIcon from 'assets/svg/crypto/eps.png'
import qkcIcon from 'assets/svg/crypto/qkc.png'
import hftIcon from 'assets/svg/crypto/hft.png'
import mtlxIcon from 'assets/svg/crypto/mtlx.png'
import daiIcon from 'assets/svg/crypto/dai.png'
import blzIcon from 'assets/svg/crypto/blz.png'
import aaveupIcon from 'assets/svg/crypto/aaveup.png'
import loomoldIcon from 'assets/svg/crypto/loomold.png'
import trbIcon from 'assets/svg/crypto/trb.png'
import dltIcon from 'assets/svg/crypto/dlt.png'
import ardrIcon from 'assets/svg/crypto/ardr.png'
import ezIcon from 'assets/svg/crypto/ez.png'
import akroIcon from 'assets/svg/crypto/akro.png'
import uniupIcon from 'assets/svg/crypto/uniup.png'
import wnxmIcon from 'assets/svg/crypto/wnxm.png'
import sunoldIcon from 'assets/svg/crypto/sunold.png'
import bttcIcon from 'assets/svg/crypto/bttc.png'
import mcIcon from 'assets/svg/crypto/mc.png'
import trigIcon from 'assets/svg/crypto/trig.png'
import lskIcon from 'assets/svg/crypto/lsk.png'
import autoIcon from 'assets/svg/crypto/auto.png'
import vaiIcon from 'assets/svg/crypto/vai.png'
import iotxIcon from 'assets/svg/crypto/iotx.png'
import snmIcon from 'assets/svg/crypto/snm.png'
import dotdownIcon from 'assets/svg/crypto/dotdown.png'
import dotIcon from 'assets/svg/crypto/dot.png'
import reefIcon from 'assets/svg/crypto/reef.png'
import ogIcon from 'assets/svg/crypto/og.png'
import zecIcon from 'assets/svg/crypto/zec.png'
import scIcon from 'assets/svg/crypto/sc.png'
import zenIcon from 'assets/svg/crypto/zen.png'
import aergoIcon from 'assets/svg/crypto/aergo.png'
import bnxIcon from 'assets/svg/crypto/bnx.png'
import bswIcon from 'assets/svg/crypto/bsw.png'
import frontIcon from 'assets/svg/crypto/front.png'
import belIcon from 'assets/svg/crypto/bel.png'
import hntIcon from 'assets/svg/crypto/hnt.png'
import inchIcon from 'assets/svg/crypto/1inch.png'
import btsIcon from 'assets/svg/crypto/bts.png'
import brdIcon from 'assets/svg/crypto/brd.png'
import galIcon from 'assets/svg/crypto/gal.png'
import trxIcon from 'assets/svg/crypto/trx.png'
import neoIcon from 'assets/svg/crypto/neo.png'
import utkIcon from 'assets/svg/crypto/utk.png'
import powrIcon from 'assets/svg/crypto/powr.png'
import coveroldIcon from 'assets/svg/crypto/coverold.png'
import stmxIcon from 'assets/svg/crypto/stmx.png'
import edgIcon from 'assets/svg/crypto/edg.png'
import maskIcon from 'assets/svg/crypto/mask.png'
import mboxIcon from 'assets/svg/crypto/mbox.png'
import blinkIcon from 'assets/svg/crypto/blink.png'
import sfpIcon from 'assets/svg/crypto/sfp.png'
import ghstIcon from 'assets/svg/crypto/ghst.png'
import xvsIcon from 'assets/svg/crypto/xvs.png'
import poeIcon from 'assets/svg/crypto/poe.png'
import jasmyIcon from 'assets/svg/crypto/jasmy.png'
import ksmIcon from 'assets/svg/crypto/ksm.png'
import engIcon from 'assets/svg/crypto/eng.png'
import nasIcon from 'assets/svg/crypto/nas.png'
import imxIcon from 'assets/svg/crypto/imx.png'
import stormIcon from 'assets/svg/crypto/storm.png'
import pyrIcon from 'assets/svg/crypto/pyr.png'
import funIcon from 'assets/svg/crypto/fun.png'
import flmIcon from 'assets/svg/crypto/flm.png'
import aavedownIcon from 'assets/svg/crypto/aavedown.png'
import aaveIcon from 'assets/svg/crypto/aave.png'
import sunIcon from 'assets/svg/crypto/sun.png'
import aionIcon from 'assets/svg/crypto/aion.png'
import perpIcon from 'assets/svg/crypto/perp.png'
import acaIcon from 'assets/svg/crypto/aca.png'
import tomoIcon from 'assets/svg/crypto/tomo.png'
import filIcon from 'assets/svg/crypto/fil.png'
import dataIcon from 'assets/svg/crypto/data.png'
import apeIcon from 'assets/svg/crypto/ape.png'
import xecIcon from 'assets/svg/crypto/xec.png'
import agixIcon from 'assets/svg/crypto/agix.png'
import manaIcon from 'assets/svg/crypto/mana.png'
import tctIcon from 'assets/svg/crypto/tct.png'
import bnbIcon from 'assets/svg/crypto/bnb.png'
import perloldIcon from 'assets/svg/crypto/perlold.png'
import looksIcon from 'assets/svg/crypto/looks.png'
import fluxIcon from 'assets/svg/crypto/flux.png'
import shibIcon from 'assets/svg/crypto/shib.png'
import cakeIcon from 'assets/svg/crypto/cake.png'
import vidtIcon from 'assets/svg/crypto/vidt.png'
import modIcon from 'assets/svg/crypto/mod.png'
import kavaIcon from 'assets/svg/crypto/kava.png'
import xymIcon from 'assets/svg/crypto/xym.png'
import rndrIcon from 'assets/svg/crypto/rndr.png'
import navIcon from 'assets/svg/crypto/nav.png'
import api3Icon from 'assets/svg/crypto/api3.png'
import snmoldIcon from 'assets/svg/crypto/snmold.png'
import clvIcon from 'assets/svg/crypto/clv.png'
import xlmupIcon from 'assets/svg/crypto/xlmup.png'
import wprIcon from 'assets/svg/crypto/wpr.png'
import superIcon from 'assets/svg/crypto/super.png'
import ctxcIcon from 'assets/svg/crypto/ctxc.png'
import xvgIcon from 'assets/svg/crypto/xvg.png'
import wanIcon from 'assets/svg/crypto/wan.png'
import cloakIcon from 'assets/svg/crypto/cloak.png'
import atomIcon from 'assets/svg/crypto/atom.png'
import hotIcon from 'assets/svg/crypto/hot.png'
import highIcon from 'assets/svg/crypto/high.png'
import npxsIcon from 'assets/svg/crypto/npxs.png'
import yfiiIcon from 'assets/svg/crypto/yfii.png'
import tlmIcon from 'assets/svg/crypto/tlm.png'
import sbtcIcon from 'assets/svg/crypto/sbtc.png'
import nuIcon from 'assets/svg/crypto/nu.png'
import oceanIcon from 'assets/svg/crypto/ocean.png'
import inchupIcon from 'assets/svg/crypto/1inchup.png'
import linkIcon from 'assets/svg/crypto/link.png'
import alphaIcon from 'assets/svg/crypto/alpha.png'
import ambIcon from 'assets/svg/crypto/amb.png'
import beamIcon from 'assets/svg/crypto/beam.png'
import mobIcon from 'assets/svg/crypto/mob.png'
import eonIcon from 'assets/svg/crypto/eon.png'
import insIcon from 'assets/svg/crypto/ins.png'
import galaIcon from 'assets/svg/crypto/gala.png'
import trxdownIcon from 'assets/svg/crypto/trxdown.png'
import dogeIcon from 'assets/svg/crypto/doge.png'
import mtlIcon from 'assets/svg/crypto/mtl.png'
import crvIcon from 'assets/svg/crypto/crv.png'
import ornIcon from 'assets/svg/crypto/orn.png'
import pundixIcon from 'assets/svg/crypto/pundix.png'
import sushiIcon from 'assets/svg/crypto/sushi.png'
import dydxIcon from 'assets/svg/crypto/dydx.png'
import sklIcon from 'assets/svg/crypto/skl.png'
import tribeIcon from 'assets/svg/crypto/tribe.png'
import stxIcon from 'assets/svg/crypto/stx.png'
import busdIcon from 'assets/svg/crypto/busd.png'
import unidownIcon from 'assets/svg/crypto/unidown.png'
import uniIcon from 'assets/svg/crypto/uni.png'
import leverIcon from 'assets/svg/crypto/lever.png'
import oxtIcon from 'assets/svg/crypto/oxt.png'
import appcIcon from 'assets/svg/crypto/appc.png'
import xlmIcon from 'assets/svg/crypto/xlm.png'
import xmrIcon from 'assets/svg/crypto/xmr.png'
import antoldIcon from 'assets/svg/crypto/antold.png'
import ethdownIcon from 'assets/svg/crypto/ethdown.png'
import avaIcon from 'assets/svg/crypto/ava.png'
import easyIcon from 'assets/svg/crypto/easy.png'
import ustcIcon from 'assets/svg/crypto/ustc.png'
import astrIcon from 'assets/svg/crypto/astr.png'
import cbmIcon from 'assets/svg/crypto/cbm.png'
import algoIcon from 'assets/svg/crypto/algo.png'
import pondIcon from 'assets/svg/crypto/pond.png'
import pyroldIcon from 'assets/svg/crypto/pyrold.png'
import grtdownIcon from 'assets/svg/crypto/grtdown.png'
import ckbIcon from 'assets/svg/crypto/ckb.png'
import omoldIcon from 'assets/svg/crypto/omold.png'
import lunIcon from 'assets/svg/crypto/lun.png'
import tvkIcon from 'assets/svg/crypto/tvk.png'
import wingIcon from 'assets/svg/crypto/wing.png'
import nvtIcon from 'assets/svg/crypto/nvt.png'
import fildownIcon from 'assets/svg/crypto/fildown.png'
import dfIcon from 'assets/svg/crypto/df.png'
import mlnIcon from 'assets/svg/crypto/mln.png'
import neblIcon from 'assets/svg/crypto/nebl.png'
import filupIcon from 'assets/svg/crypto/filup.png'
import saltIcon from 'assets/svg/crypto/salt.png'
import flowIcon from 'assets/svg/crypto/flow.png'
import stptIcon from 'assets/svg/crypto/stpt.png'
import wrxIcon from 'assets/svg/crypto/wrx.png'
import ancIcon from 'assets/svg/crypto/anc.png'
import spartaoldIcon from 'assets/svg/crypto/spartaold.png'
import renbtcIcon from 'assets/svg/crypto/renbtc.png'
import bandIcon from 'assets/svg/crypto/band.png'
import elonIcon from 'assets/svg/crypto/elon.png'
import sxpdownIcon from 'assets/svg/crypto/sxpdown.png'
import peopleIcon from 'assets/svg/crypto/people.png'
import idexIcon from 'assets/svg/crypto/idex.png'
import xrpIcon from 'assets/svg/crypto/xrp.png'
import alcxIcon from 'assets/svg/crypto/alcx.png'
import gmtIcon from 'assets/svg/crypto/gmt.png'
import paxIcon from 'assets/svg/crypto/pax.png'
import aliceIcon from 'assets/svg/crypto/alice.png'
import fidaIcon from 'assets/svg/crypto/fida.png'
import omIcon from 'assets/svg/crypto/om.png'
import bncIcon from 'assets/svg/crypto/bnc.png'
import truIcon from 'assets/svg/crypto/tru.png'
import duskIcon from 'assets/svg/crypto/dusk.png'
import xtzdownIcon from 'assets/svg/crypto/xtzdown.png'
import vibIcon from 'assets/svg/crypto/vib.png'
import qtumIcon from 'assets/svg/crypto/qtum.png'
import ctsiIcon from 'assets/svg/crypto/ctsi.png'
import wabiIcon from 'assets/svg/crypto/wabi.png'
import nanoIcon from 'assets/svg/crypto/nano.png'
import nexoIcon from 'assets/svg/crypto/nexo.png'
import icxIcon from 'assets/svg/crypto/icx.png'
import jexIcon from 'assets/svg/crypto/jex.png'
import mdtIcon from 'assets/svg/crypto/mdt.png'
import umaIcon from 'assets/svg/crypto/uma.png'
import xtzupIcon from 'assets/svg/crypto/xtzup.png'
import arkIcon from 'assets/svg/crypto/ark.png'
import atdIcon from 'assets/svg/crypto/atd.png'
import agiIcon from 'assets/svg/crypto/agi.png'
import nearIcon from 'assets/svg/crypto/near.png'
import elfIcon from 'assets/svg/crypto/elf.png'
import repIcon from 'assets/svg/crypto/rep.png'
import celrIcon from 'assets/svg/crypto/celr.png'
import farmIcon from 'assets/svg/crypto/farm.png'
import rlcIcon from 'assets/svg/crypto/rlc.png'
import vrtIcon from 'assets/svg/crypto/vrt.png'
import forthIcon from 'assets/svg/crypto/forth.png'
import winIcon from 'assets/svg/crypto/win.png'
import rifIcon from 'assets/svg/crypto/rif.png'
import perlIcon from 'assets/svg/crypto/perl.png'
import ftmIcon from 'assets/svg/crypto/ftm.png'
import keyIcon from 'assets/svg/crypto/key.png'
import erdIcon from 'assets/svg/crypto/erd.png'
import mdxIcon from 'assets/svg/crypto/mdx.png'
import botIcon from 'assets/svg/crypto/bot.png'
import movrIcon from 'assets/svg/crypto/movr.png'
import coverIcon from 'assets/svg/crypto/cover.png'
import bullIcon from 'assets/svg/crypto/bull.png'
import wavesIcon from 'assets/svg/crypto/waves.png'
import dotupIcon from 'assets/svg/crypto/dotup.png'
import usdcIcon from 'assets/svg/crypto/usdc.png'
import irisIcon from 'assets/svg/crypto/iris.png'
import bnbbullIcon from 'assets/svg/crypto/bnbbull.png'
import cosIcon from 'assets/svg/crypto/cos.png'
import wethIcon from 'assets/svg/crypto/weth.png'
import ogvIcon from 'assets/svg/crypto/ogv.png'
import juvIcon from 'assets/svg/crypto/juv.png'
import btcupIcon from 'assets/svg/crypto/btcup.png'
import anyIcon from 'assets/svg/crypto/any.png'
import osmoIcon from 'assets/svg/crypto/osmo.png'
import etfIcon from 'assets/svg/crypto/etf.png'
import antIcon from 'assets/svg/crypto/ant.png'
import nbsIcon from 'assets/svg/crypto/nbs.png'
import dockIcon from 'assets/svg/crypto/dock.png'
import fuelIcon from 'assets/svg/crypto/fuel.png'
import scrtIcon from 'assets/svg/crypto/scrt.png'
import kdaIcon from 'assets/svg/crypto/kda.png'
import creamIcon from 'assets/svg/crypto/cream.png'
import acmIcon from 'assets/svg/crypto/acm.png'
import nknIcon from 'assets/svg/crypto/nkn.png'
import cotiIcon from 'assets/svg/crypto/coti.png'
import achIcon from 'assets/svg/crypto/ach.png'
import ltooldIcon from 'assets/svg/crypto/ltoold.png'
import bqxIcon from 'assets/svg/crypto/bqx.png'
import phaIcon from 'assets/svg/crypto/pha.png'
import bchdownIcon from 'assets/svg/crypto/bchdown.png'
import avaxIcon from 'assets/svg/crypto/avax.png'
import wsolIcon from 'assets/svg/crypto/wsol.png'
import minaIcon from 'assets/svg/crypto/mina.png'
import lendIcon from 'assets/svg/crypto/lend.png'
import ankrIcon from 'assets/svg/crypto/ankr.png'
import onxIcon from 'assets/svg/crypto/onx.png'
import santosIcon from 'assets/svg/crypto/santos.png'
import poaIcon from 'assets/svg/crypto/poa.png'
import xtzIcon from 'assets/svg/crypto/xtz.png'
import uftIcon from 'assets/svg/crypto/uft.png'
import wtcIcon from 'assets/svg/crypto/wtc.png'
import qiswapIcon from 'assets/svg/crypto/qiswap.png'
import chzIcon from 'assets/svg/crypto/chz.png'
import aptIcon from 'assets/svg/crypto/apt.png'
import fetIcon from 'assets/svg/crypto/fet.png'
import opIcon from 'assets/svg/crypto/op.png'
import bethIcon from 'assets/svg/crypto/beth.png'
import cvpIcon from 'assets/svg/crypto/cvp.png'
import cfxIcon from 'assets/svg/crypto/cfx.png'
import mblIcon from 'assets/svg/crypto/mbl.png'
import bvndIcon from 'assets/svg/crypto/bvnd.png'
import xdataIcon from 'assets/svg/crypto/xdata.png'
import klayIcon from 'assets/svg/crypto/klay.png'
import grtupIcon from 'assets/svg/crypto/grtup.png'
import susdIcon from 'assets/svg/crypto/susd.png'
import injIcon from 'assets/svg/crypto/inj.png'
import eosIcon from 'assets/svg/crypto/eos.png'
import sysIcon from 'assets/svg/crypto/sys.png'
import straxIcon from 'assets/svg/crypto/strax.png'
import cvcIcon from 'assets/svg/crypto/cvc.png'
import cbkIcon from 'assets/svg/crypto/cbk.png'
import subIcon from 'assets/svg/crypto/sub.png'
import slpoldIcon from 'assets/svg/crypto/slpold.png'
import cndIcon from 'assets/svg/crypto/cnd.png'
import bchaIcon from 'assets/svg/crypto/bcha.png'
import edoIcon from 'assets/svg/crypto/edo.png'
import wingsIcon from 'assets/svg/crypto/wings.png'
import plaIcon from 'assets/svg/crypto/pla.png'
import jstIcon from 'assets/svg/crypto/jst.png'
import adaupIcon from 'assets/svg/crypto/adaup.png'
import steemIcon from 'assets/svg/crypto/steem.png'
import adxIcon from 'assets/svg/crypto/adx.png'
import nftIcon from 'assets/svg/crypto/nft.png'
import ethupIcon from 'assets/svg/crypto/ethup.png'
import maIcon from 'assets/svg/crypto/ma.png'
import nxsIcon from 'assets/svg/crypto/nxs.png'
import iotaIcon from 'assets/svg/crypto/iota.png'
import alpineIcon from 'assets/svg/crypto/alpine.png'
import ethbullIcon from 'assets/svg/crypto/ethbull.png'
import arIcon from 'assets/svg/crypto/ar.png'
import canIcon from 'assets/svg/crypto/can.png'
import iostIcon from 'assets/svg/crypto/iost.png'
import vgxIcon from 'assets/svg/crypto/vgx.png'
import mirIcon from 'assets/svg/crypto/mir.png'
import slpIcon from 'assets/svg/crypto/slp.png'
import mdaIcon from 'assets/svg/crypto/mda.png'
import ltcdownIcon from 'assets/svg/crypto/ltcdown.png'
import tornIcon from 'assets/svg/crypto/torn.png'
import rareIcon from 'assets/svg/crypto/rare.png'
import bttIcon from 'assets/svg/crypto/btt.png'
import celoIcon from 'assets/svg/crypto/celo.png'
import spartaIcon from 'assets/svg/crypto/sparta.png'
import ssvIcon from 'assets/svg/crypto/ssv.png'
import adaIcon from 'assets/svg/crypto/ada.png'
import rayIcon from 'assets/svg/crypto/ray.png'
import usdpIcon from 'assets/svg/crypto/usdp.png'
import boltIcon from 'assets/svg/crypto/bolt.png'
import qntIcon from 'assets/svg/crypto/qnt.png'
import nulsIcon from 'assets/svg/crypto/nuls.png'
import pntIcon from 'assets/svg/crypto/pnt.png'
import bicoIcon from 'assets/svg/crypto/bico.png'
import rdnIcon from 'assets/svg/crypto/rdn.png'
import eosupIcon from 'assets/svg/crypto/eosup.png'
import burgerIcon from 'assets/svg/crypto/burger.png'
import addIcon from 'assets/svg/crypto/add.png'
import diaIcon from 'assets/svg/crypto/dia.png'
import iqIcon from 'assets/svg/crypto/iq.png'
import keyfiIcon from 'assets/svg/crypto/keyfi.png'
import lazioIcon from 'assets/svg/crypto/lazio.png'
import mkrIcon from 'assets/svg/crypto/mkr.png'
import xnoIcon from 'assets/svg/crypto/xno.png'
import xrpbullIcon from 'assets/svg/crypto/xrpbull.png'
import entrpIcon from 'assets/svg/crypto/entrp.png'
import ncashIcon from 'assets/svg/crypto/ncash.png'
import usdsIcon from 'assets/svg/crypto/usds.png'
import sushiupIcon from 'assets/svg/crypto/sushiup.png'
import btcdownIcon from 'assets/svg/crypto/btcdown.png'
import bzrxIcon from 'assets/svg/crypto/bzrx.png'
import ensIcon from 'assets/svg/crypto/ens.png'
import qiIcon from 'assets/svg/crypto/qi.png'
import goIcon from 'assets/svg/crypto/go.png'
import arnIcon from 'assets/svg/crypto/arn.png'
import purseIcon from 'assets/svg/crypto/purse.png'
import ethbearIcon from 'assets/svg/crypto/ethbear.png'
import magicIcon from 'assets/svg/crypto/magic.png'
import glmrIcon from 'assets/svg/crypto/glmr.png'
import lunaIcon from 'assets/svg/crypto/luna.png'
import bntIcon from 'assets/svg/crypto/bnt.png'
import reqIcon from 'assets/svg/crypto/req.png'
import ethbntIcon from 'assets/svg/crypto/ethbnt.png'
import trxupIcon from 'assets/svg/crypto/trxup.png'
import linkupIcon from 'assets/svg/crypto/linkup.png'
import badgerIcon from 'assets/svg/crypto/badger.png'
import xemIcon from 'assets/svg/crypto/xem.png'
import bifiIcon from 'assets/svg/crypto/bifi.png'
import vthoIcon from 'assets/svg/crypto/vtho.png'
import bakeIcon from 'assets/svg/crypto/bake.png'
import fxsIcon from 'assets/svg/crypto/fxs.png'
import kp3rIcon from 'assets/svg/crypto/kp3r.png'
import bchIcon from 'assets/svg/crypto/bch.png'
import pptIcon from 'assets/svg/crypto/ppt.png'
import bttoldIcon from 'assets/svg/crypto/bttold.png'
import waxpIcon from 'assets/svg/crypto/waxp.png'
import skyIcon from 'assets/svg/crypto/sky.png'
import tIcon from 'assets/svg/crypto/t.png'
import xprIcon from 'assets/svg/crypto/xpr.png'
import sntIcon from 'assets/svg/crypto/snt.png'
import bgbpIcon from 'assets/svg/crypto/bgbp.png'
import thetaIcon from 'assets/svg/crypto/theta.png'
import kmdIcon from 'assets/svg/crypto/kmd.png'
import tusdIcon from 'assets/svg/crypto/tusd.png'
import radIcon from 'assets/svg/crypto/rad.png'
import twtIcon from 'assets/svg/crypto/twt.png'
import idrtIcon from 'assets/svg/crypto/idrt.png'
import chatIcon from 'assets/svg/crypto/chat.png'
import omgIcon from 'assets/svg/crypto/omg.png'
import drepIcon from 'assets/svg/crypto/drep.png'
import ognIcon from 'assets/svg/crypto/ogn.png'
import ostIcon from 'assets/svg/crypto/ost.png'
import phbv1Icon from 'assets/svg/crypto/phbv1.png'
import aeIcon from 'assets/svg/crypto/ae.png'
import bchsvIcon from 'assets/svg/crypto/bchsv.png'
import bchupIcon from 'assets/svg/crypto/bchup.png'
import btcIcon from 'assets/svg/crypto/btc.png'
import btcstIcon from 'assets/svg/crypto/btcst.png'
import cvxIcon from 'assets/svg/crypto/cvx.png'
import darIcon from 'assets/svg/crypto/dar.png'
import AAVEIcon from 'assets/svg/crypto/eop.png'
import ernIcon from 'assets/svg/crypto/ern.png'
import ethwIcon from 'assets/svg/crypto/ethw.png'
import fttIcon from 'assets/svg/crypto/ftt.png'
import hiveIcon from 'assets/svg/crypto/hive.png'
import nsbtIcon from 'assets/svg/crypto/nsbt.png'
import oneIcon from 'assets/svg/crypto/one.png'
import qlcIcon from 'assets/svg/crypto/qlc.png'
import rgtIcon from 'assets/svg/crypto/rgt.png'
import snglsIcon from 'assets/svg/crypto/sngls.png'
import srmIcon from 'assets/svg/crypto/srm.png'
import sxpupIcon from 'assets/svg/crypto/sxpup.png'
import unknownIcon from 'assets/svg/crypto/unknown.png'
import vetIcon from 'assets/svg/crypto/vet.png'
import { SynthsName } from 'constants/currency'

export const SYNTH_ICONS: Record< SynthsName | string, any> = {
	KWENTA: KWENTAIcon,
	WBTC: WBTCIcon,
	ERN: ernIcon,
	VET: vetIcon,
	CVX: cvxIcon,
	ONE: oneIcon,
	BTCST: btcstIcon,
	ETHW: ethwIcon,
	BTC: btcIcon,
	NSBT: nsbtIcon,
	BCHUP: bchupIcon,
	HIVE: hiveIcon,
	SXPUP: sxpupIcon,
	RGT: rgtIcon,
	QLC: qlcIcon,
	BCHSV: bchsvIcon,
	FTT: fttIcon,
	SRM: srmIcon,
	DAR: darIcon,
	SNGLS: snglsIcon,
	HARD: hardIcon,
	EPX: epxIcon,
	BIDR: bidrIcon,
	JOE: joeIcon,
	MFT: mftIcon,
	AST: astIcon,
	QUICK: quickIcon,
	SOL: solIcon,
	YFIUP: yfiupIcon,
	GLM: glmIcon,
	BNBBEAR: bnbbearIcon,
	MEETONE: meetoneIcon,
	ROSE: roseIcon,
	PARA: paraIcon,
	LTC: ltcIcon,
	VITE: viteIcon,
	ENJ: enjIcon,
	DASH: dashIcon,
	STG: stgIcon,
	EFI: efiIcon,
	GTO: gtoIcon,
	EGLD: egldIcon,
	VOXEL: voxelIcon,
	EOSDOWN: eosdownIcon,
	GVT: gvtIcon,
	STORJ: storjIcon,
	CHR: chrIcon,
	REN: renIcon,
	COCOS: cocosIcon,
	COMP: compIcon,
	EVX: evxIcon,
	ZRX: zrxIcon,
	UNFI: unfiIcon,
	LTO: ltoIcon,
	LOKA: lokaIcon,
	GRS: grsIcon,
	OAX: oaxIcon,
	GAS: gasIcon,
	ALPACA: alpacaIcon,
	REI: reiIcon,
	UST: ustIcon,
	XRPBEAR: xrpbearIcon,
	AUCTION: auctionIcon,
	GYEN: gyenIcon,
	HC: hcIcon,
	BOND: bondIcon,
	POLYX: polyxIcon,
	ETH: ethIcon,
	POLS: polsIcon,
	BNBUP: bnbupIcon,
	PSG: psgIcon,
	WBNB: wbnbIcon,
	XLMDOWN: xlmdownIcon,
	VRAB: vrabIcon,
	BAL: balIcon,
	ONT: ontIcon,
	PAXG: paxgIcon,
	PORTO: portoIcon,
	MTH: mthIcon,
	CHESS: chessIcon,
	SWRV: swrvIcon,
	RCN: rcnIcon,
	SGB: sgbIcon,
	BEAR: bearIcon,
	PHB: phbIcon,
	DGB: dgbIcon,
	BNBDOWN: bnbdownIcon,
	FIO: fioIcon,
	YGG: yggIcon,
	SNX: snxIcon,
	OOKI: ookiIcon,
	PROM: promIcon,
	BETA: betaIcon,
	ETC: etcIcon,
	SUSHIDOWN: sushidownIcon,
	LPT: lptIcon,
	XRPDOWN: xrpdownIcon,
	QSP: qspIcon,
	FIRO: firoIcon,
	BCD: bcdIcon,
	AMP: ampIcon,
	TKO: tkoIcon,
	LDO: ldoIcon,
	ICP: icpIcon,
	WOO: wooIcon,
	GXS: gxsIcon,
	XRPUP: xrpupIcon,
	EOSBULL: eosbullIcon,
	MULTI: multiIcon,
	ATA: ataIcon,
	DEXE: dexeIcon,
	BAR: barIcon,
	YOYO: yoyoIcon,
	ZIL: zilIcon,
	POLY: polyIcon,
	BAT: batIcon,
	GNT: gntIcon,
	CITY: cityIcon,
	ATM: atmIcon,
	LBA: lbaIcon,
	LTCUP: ltcupIcon,
	BKRW: bkrwIcon,
	DON: donIcon,
	KEEP: keepIcon,
	FIS: fisIcon,
	INCHDOWN: inchdownIcon,
	CTR: ctrIcon,
	YFI: yfiIcon,
	YFIDOWN: yfidownIcon,
	BDOT: bdotIcon,
	GTC: gtcIcon,
	EOP: eopIcon,
	USDT: usdtIcon,
	RUNE: runeIcon,
	KNCL: knclIcon,
	PROS: prosIcon,
	CDT: cdtIcon,
	PIVX: pivxIcon,
	EOSBEAR: eosbearIcon,
	SGT: sgtIcon,
	BOBA: bobaIcon,
	GNO: gnoIcon,
	SPELL: spellIcon,
	C: c98Icon,
	SOLO: soloIcon,
	LRC: lrcIcon,
	TFUEL: tfuelIcon,
	DNT: dntIcon,
	RSR: rsrIcon,
	MATIC: maticIcon,
	ARPA: arpaIcon,
	REPV: repv1Icon,
	BTG: btgIcon,
	NMR: nmrIcon,
	AGLD: agldIcon,
	UND: undIcon,
	FOR: forIcon,
	LUNC: luncIcon,
	HEGIC: hegicIcon,
	DCR: dcrIcon,
	LIT: litIcon,
	AXS: axsIcon,
	HBAR: hbarIcon,
	SXP: sxpIcon,
	KNC: kncIcon,
	SAND: sandIcon,
	LINKDOWN: linkdownIcon,
	ADADOWN: adadownIcon,
	AXSOLD: axsoldIcon,
	AUDIO: audioIcon,
	ONG: ongIcon,
	ASR: asrIcon,
	ADXOLD: adxoldIcon,
	LINA: linaIcon,
	HNST: hnstIcon,
	TROY: troyIcon,
	HCC: hccIcon,
	LOOM: loomIcon,
	RVN: rvnIcon,
	MITH: mithIcon,
	ILV: ilvIcon,
	DODO: dodoIcon,
	BCX: bcxIcon,
	CTK: ctkIcon,
	VIA: viaIcon,
	DREPOLD: drepoldIcon,
	RAMP: rampIcon,
	GRT: grtIcon,
	DENT: dentIcon,
	DEGO: degoIcon,
	EPS: epsIcon,
	QKC: qkcIcon,
	HFT: hftIcon,
	MTLX: mtlxIcon,
	DAI: daiIcon,
	BLZ: blzIcon,
	AAVEUP: aaveupIcon,
	LOOMOLD: loomoldIcon,
	TRB: trbIcon,
	DLT: dltIcon,
	ARDR: ardrIcon,
	EZ: ezIcon,
	AKRO: akroIcon,
	UNIUP: uniupIcon,
	WNXM: wnxmIcon,
	SUNOLD: sunoldIcon,
	BTTC: bttcIcon,
	MC: mcIcon,
	TRIG: trigIcon,
	LSK: lskIcon,
	AUTO: autoIcon,
	VAI: vaiIcon,
	IOTX: iotxIcon,
	SNM: snmIcon,
	DOTDOWN: dotdownIcon,
	DOT: dotIcon,
	REEF: reefIcon,
	OG: ogIcon,
	ZEC: zecIcon,
	SC: scIcon,
	ZEN: zenIcon,
	AERGO: aergoIcon,
	BNX: bnxIcon,
	BSW: bswIcon,
	FRONT: frontIcon,
	BEL: belIcon,
	HNT: hntIcon,
	INCH: inchIcon,
	BTS: btsIcon,
	BRD: brdIcon,
	GAL: galIcon,
	TRX: trxIcon,
	NEO: neoIcon,
	UTK: utkIcon,
	POWR: powrIcon,
	COVEROLD: coveroldIcon,
	STMX: stmxIcon,
	EDG: edgIcon,
	MASK: maskIcon,
	MBOX: mboxIcon,
	BLINK: blinkIcon,
	SFP: sfpIcon,
	GHST: ghstIcon,
	XVS: xvsIcon,
	POE: poeIcon,
	JASMY: jasmyIcon,
	KSM: ksmIcon,
	ENG: engIcon,
	NAS: nasIcon,
	IMX: imxIcon,
	STORM: stormIcon,
	PYR: pyrIcon,
	FUN: funIcon,
	FLM: flmIcon,
	AAVEDOWN: aavedownIcon,
	AAVE: aaveIcon,
	SUN: sunIcon,
	AION: aionIcon,
	PERP: perpIcon,
	ACA: acaIcon,
	TOMO: tomoIcon,
	FIL: filIcon,
	DATA: dataIcon,
	APE: apeIcon,
	XEC: xecIcon,
	AGIX: agixIcon,
	MANA: manaIcon,
	TCT: tctIcon,
	BNB: bnbIcon,
	PERLOLD: perloldIcon,
	LOOKS: looksIcon,
	FLUX: fluxIcon,
	SHIB: shibIcon,
	CAKE: cakeIcon,
	VIDT: vidtIcon,
	MOD: modIcon,
	KAVA: kavaIcon,
	XYM: xymIcon,
	RNDR: rndrIcon,
	NAV: navIcon,
	API: api3Icon,
	SNMOLD: snmoldIcon,
	CLV: clvIcon,
	XLMUP: xlmupIcon,
	WPR: wprIcon,
	SUPER: superIcon,
	CTXC: ctxcIcon,
	XVG: xvgIcon,
	WAN: wanIcon,
	CLOAK: cloakIcon,
	ATOM: atomIcon,
	HOT: hotIcon,
	HIGH: highIcon,
	NPXS: npxsIcon,
	YFII: yfiiIcon,
	TLM: tlmIcon,
	SBTC: sbtcIcon,
	NU: nuIcon,
	OCEAN: oceanIcon,
	INCHUP: inchupIcon,
	LINK: linkIcon,
	ALPHA: alphaIcon,
	AMB: ambIcon,
	BEAM: beamIcon,
	MOB: mobIcon,
	EON: eonIcon,
	INS: insIcon,
	GALA: galaIcon,
	TRXDOWN: trxdownIcon,
	DOGE: dogeIcon,
	MTL: mtlIcon,
	CRV: crvIcon,
	ORN: ornIcon,
	PUNDIX: pundixIcon,
	SUSHI: sushiIcon,
	DYDX: dydxIcon,
	SKL: sklIcon,
	TRIBE: tribeIcon,
	STX: stxIcon,
	BUSD: busdIcon,
	UNIDOWN: unidownIcon,
	UNI: uniIcon,
	LEVER: leverIcon,
	OXT: oxtIcon,
	APPC: appcIcon,
	XLM: xlmIcon,
	XMR: xmrIcon,
	ANTOLD: antoldIcon,
	ETHDOWN: ethdownIcon,
	AVA: avaIcon,
	EASY: easyIcon,
	USTC: ustcIcon,
	ASTR: astrIcon,
	CBM: cbmIcon,
	ALGO: algoIcon,
	POND: pondIcon,
	PYROLD: pyroldIcon,
	GRTDOWN: grtdownIcon,
	CKB: ckbIcon,
	OMOLD: omoldIcon,
	LUN: lunIcon,
	TVK: tvkIcon,
	WING: wingIcon,
	NVT: nvtIcon,
	FILDOWN: fildownIcon,
	DF: dfIcon,
	MLN: mlnIcon,
	NEBL: neblIcon,
	FILUP: filupIcon,
	SALT: saltIcon,
	FLOW: flowIcon,
	STPT: stptIcon,
	WRX: wrxIcon,
	ANC: ancIcon,
	SPARTAOLD: spartaoldIcon,
	RENBTC: renbtcIcon,
	BAND: bandIcon,
	ELON: elonIcon,
	SXPDOWN: sxpdownIcon,
	PEOPLE: peopleIcon,
	IDEX: idexIcon,
	XRP: xrpIcon,
	ALCX: alcxIcon,
	GMT: gmtIcon,
	PAX: paxIcon,
	ALICE: aliceIcon,
	FIDA: fidaIcon,
	OM: omIcon,
	BNC: bncIcon,
	TRU: truIcon,
	DUSK: duskIcon,
	XTZDOWN: xtzdownIcon,
	VIB: vibIcon,
	QTUM: qtumIcon,
	CTSI: ctsiIcon,
	WABI: wabiIcon,
	NANO: nanoIcon,
	NEXO: nexoIcon,
	ICX: icxIcon,
	JEX: jexIcon,
	MDT: mdtIcon,
	UMA: umaIcon,
	XTZUP: xtzupIcon,
	ARK: arkIcon,
	ATD: atdIcon,
	AGI: agiIcon,
	NEAR: nearIcon,
	ELF: elfIcon,
	REP: repIcon,
	CELR: celrIcon,
	FARM: farmIcon,
	RLC: rlcIcon,
	VRT: vrtIcon,
	FORTH: forthIcon,
	WIN: winIcon,
	RIF: rifIcon,
	PERL: perlIcon,
	FTM: ftmIcon,
	KEY: keyIcon,
	ERD: erdIcon,
	MDX: mdxIcon,
	BOT: botIcon,
	MOVR: movrIcon,
	COVER: coverIcon,
	BULL: bullIcon,
	WAVES: wavesIcon,
	DOTUP: dotupIcon,
	USDC: usdcIcon,
	IRIS: irisIcon,
	BNBBULL: bnbbullIcon,
	COS: cosIcon,
	WETH: wethIcon,
	OGV: ogvIcon,
	JUV: juvIcon,
	BTCUP: btcupIcon,
	ANY: anyIcon,
	OSMO: osmoIcon,
	ETF: etfIcon,
	ANT: antIcon,
	NBS: nbsIcon,
	DOCK: dockIcon,
	FUEL: fuelIcon,
	SCRT: scrtIcon,
	KDA: kdaIcon,
	CREAM: creamIcon,
	ACM: acmIcon,
	NKN: nknIcon,
	COTI: cotiIcon,
	ACH: achIcon,
	LTOOLD: ltooldIcon,
	BQX: bqxIcon,
	PHA: phaIcon,
	BCHDOWN: bchdownIcon,
	AVAX: avaxIcon,
	WSOL: wsolIcon,
	MINA: minaIcon,
	LEND: lendIcon,
	ANKR: ankrIcon,
	ONX: onxIcon,
	SANTOS: santosIcon,
	POA: poaIcon,
	XTZ: xtzIcon,
	UFT: uftIcon,
	WTC: wtcIcon,
	QISWAP: qiswapIcon,
	CHZ: chzIcon,
	APT: aptIcon,
	FET: fetIcon,
	OP: opIcon,
	BETH: bethIcon,
	CVP: cvpIcon,
	CFX: cfxIcon,
	MBL: mblIcon,
	BVND: bvndIcon,
	XDATA: xdataIcon,
	KLAY: klayIcon,
	GRTUP: grtupIcon,
	SUSD: susdIcon,
	INJ: injIcon,
	EOS: eosIcon,
	SYS: sysIcon,
	STRAX: straxIcon,
	CVC: cvcIcon,
	CBK: cbkIcon,
	SUB: subIcon,
	SLPOLD: slpoldIcon,
	CND: cndIcon,
	BCHA: bchaIcon,
	EDO: edoIcon,
	WINGS: wingsIcon,
	PLA: plaIcon,
	JST: jstIcon,
	ADAUP: adaupIcon,
	STEEM: steemIcon,
	ADX: adxIcon,
	NFT: nftIcon,
	ETHUP: ethupIcon,
	MA: maIcon,
	NXS: nxsIcon,
	IOTA: iotaIcon,
	ALPINE: alpineIcon,
	ETHBULL: ethbullIcon,
	AR: arIcon,
	CAN: canIcon,
	IOST: iostIcon,
	VGX: vgxIcon,
	MIR: mirIcon,
	SLP: slpIcon,
	MDA: mdaIcon,
	LTCDOWN: ltcdownIcon,
	TORN: tornIcon,
	RARE: rareIcon,
	BTT: bttIcon,
	CELO: celoIcon,
	SPARTA: spartaIcon,
	SSV: ssvIcon,
	ADA: adaIcon,
	RAY: rayIcon,
	USDP: usdpIcon,
	BOLT: boltIcon,
	QNT: qntIcon,
	NULS: nulsIcon,
	PNT: pntIcon,
	BICO: bicoIcon,
	RDN: rdnIcon,
	EOSUP: eosupIcon,
	BURGER: burgerIcon,
	ADD: addIcon,
	DIA: diaIcon,
	IQ: iqIcon,
	KEYFI: keyfiIcon,
	LAZIO: lazioIcon,
	MKR: mkrIcon,
	XNO: xnoIcon,
	XRPBULL: xrpbullIcon,
	ENTRP: entrpIcon,
	NCASH: ncashIcon,
	USDS: usdsIcon,
	SUSHIUP: sushiupIcon,
	BTCDOWN: btcdownIcon,
	BZRX: bzrxIcon,
	ENS: ensIcon,
	QI: qiIcon,
	GO: goIcon,
	ARN: arnIcon,
	PURSE: purseIcon,
	ETHBEAR: ethbearIcon,
	MAGIC: magicIcon,
	GLMR: glmrIcon,
	LUNA: lunaIcon,
	BNT: bntIcon,
	REQ: reqIcon,
	ETHBNT: ethbntIcon,
	TRXUP: trxupIcon,
	LINKUP: linkupIcon,
	BADGER: badgerIcon,
	XEM: xemIcon,
	BIFI: bifiIcon,
	VTHO: vthoIcon,
	BAKE: bakeIcon,
	FXS: fxsIcon,
	KPR: kp3rIcon,
	BCH: bchIcon,
	PPT: pptIcon,
	BTTOLD: bttoldIcon,
	WAXP: waxpIcon,
	SKY: skyIcon,
	T: tIcon,
	XPR: xprIcon,
	SNT: sntIcon,
	BGBP: bgbpIcon,
	THETA: thetaIcon,
	KMD: kmdIcon,
	TUSD: tusdIcon,
	RAD: radIcon,
	TWT: twtIcon,
	IDRT: idrtIcon,
	CHAT: chatIcon,
	OMG: omgIcon,
	DREP: drepIcon,
	OGN: ognIcon,
	OST: ostIcon,
	PHBV: phbv1Icon,
	AE: aeIcon,
	UNKNOWN: unknownIcon,
}
