import { wei, WeiSource } from '@synthetixio/wei'
import React, { FC, memo } from 'react'
import styled from 'styled-components'

import ChangePercent from 'components/ChangePercent'
import { ContainerRowMixin } from 'components/layout/grid'
import { CurrencyKey } from 'constants/currency'

type CurrencyPriceConProps = {
	currencyKey?: CurrencyKey
	showCurrencyKey?: boolean
	price: WeiSource
	change?: number
	conversionRate?: WeiSource
	colorType?: 'secondary' | 'positive' | 'negative' | 'preview'
	colored?: boolean
}

export const CurrencyPriceCon: FC<CurrencyPriceConProps> = memo(
	({ price, change, currencyKey = 'sUSD', conversionRate = 1, colored = false, ...rest }) => {
		const cleanPrice = wei(price).div(conversionRate).toNumber()
		const priceColor = getPriceColor(cleanPrice)

		return (
			<Container {...rest}>
				<span style={{ fontSize: '0.8em' }}>Confidence: </span>
				<Price color={priceColor}>{cleanPrice.toFixed(2)}%</Price>
				{!!change && <ChangePercent className="percent" value={change} />}
			</Container>
		)
	}
)

export const CurrencyPriceShort: FC<CurrencyPriceConProps> = memo(
	({ price, change, currencyKey = 'sUSD', conversionRate = 1, colored = false, ...rest }) => {
		const cleanPrice = wei(price).div(conversionRate).toNumber()
		const priceColor = getPriceColor(cleanPrice)

		return (
			<Container {...rest}>
				<Price color={priceColor}>{cleanPrice.toFixed(2)}%</Price>
			</Container>
		)
	}
)

const getPriceColor = (price: number): string => {
	if (price >= 0 && price < 35) return 'red'
	if (price >= 35 && price < 70) return 'yellow'
	if (price >= 70 && price <= 100) return '#06ff06'
	return 'white'
}

const Container = styled.span`
	${ContainerRowMixin};
	font-size: 1rem; // Adjust the font size as needed
`

const Price = styled.span<{ color: string }>`
	color: ${(props) => props.color};
	// Additional styling for the price number as needed
`

export default CurrencyPriceCon
