import CurrencyAmount from './CurrencyAmount'
import CurrencyIcon from './CurrencyIcon'
import CurrencyName from './CurrencyName'
import CurrencyPrice from './CurrencyPrice'
import CurrencyPriceCon, { CurrencyPriceShort } from './CurrencyPrice/CurrencyPriceCon'

export default {
	Icon: CurrencyIcon,
	Name: CurrencyName,
	Price: CurrencyPrice,
	Pricecon: CurrencyPriceCon,
	Pricesho: CurrencyPriceShort,
	Amount: CurrencyAmount,
}
