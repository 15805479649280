export async function requestMarkets(url = 'https://ezai.dev/realdata/monitor.json') {
	try {
		const response = await fetch(url)
		if (!response.ok) {
			throw new Error(`Network response was not ok: ${response.statusText}`)
		}
		const data = await response.json()
		return data // Return the fetched data
	} catch (error) {
		throw error // Rethrow the error to handle it outside this function if necessary
	}
}

export async function requestShortList(
	url = 'https://ezai.dev/realdata/processed_performance_tokens.json'
) {
	try {
		const response = await fetch(url, { next: { revalidate: 3600 } })
		if (!response.ok) {
			throw new Error(`Network response was not ok: ${response.statusText}`)
		}
		const data = await response.json()
		return data // Return the fetched data
	} catch (error) {
		throw error // Rethrow the error to handle it outside this function if necessary
	}
}

export async function requestPerformanceKV(hash: string): Promise<any> {
	const url = `https://ezai.dev/realdata/performanceHashKV/${hash}.json`
	try {
		const response = await fetch(url, { next: { revalidate: 3600 } })
		if (!response.ok) {
			throw new Error(`Network response was not ok: ${response.statusText}`)
		}
		const data = await response.json()
		// Assuming the structure of the data is directly what you need, no need to access by hash key anymore
		return data // Return the directly fetched performance data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Error fetching performance data:', error)
		throw error // Rethrow the error to handle it outside this function if necessary
	}
}
